@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}
:root{
  --text-color-light: hsl(0, 0%, 0%);
  --small-font-size: 0.813rem;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  background: radial-gradient(at top right, #015d9f 2%, #2A6F97 40%, #2C7DA0 60%, #468FAF 70%, #61A5C2 95%);
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  font-family: 'Centra', sans-serif !important;
  margin: 0 0 3rem 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}
.change-language::active{
  border:hsl(0, 0%, 0%) 100px solid;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 100px;
  }

}
@media screen and (max-device-width: 480px)
and (orientation: portrait) {
  .card-wrapper{
    margin-bottom: 15px;
  }
  .page-wrapper{
    padding: 0px !important;
  }
  .section__title{
    margin-bottom: 20px;
  }
  .banner button {
    margin-bottom: 40px;
  }
  .banner {
    padding: 100px 0 100px 0 !important;
  }
  .scrollingDown{
    display: none !important;
  }
  .banner h1{
    font-size: 50px !important;
  }
  .qualification_textbox{
    overflow-wrap: normal;
    max-width: 90px !important;
  }
  .animation__zoomIn{
    animation-name: none !important;
    -webkit-animation-name: none !important;
  }	
  .animate__fadeIn{
    animation-name: none !important;
    -webkit-animation-name: none !important;
  }
  .animate__animated{
    animation-name: none !important;
    -webkit-animation-name: none !important;
  }
  .navbar-light .navbar-toggler {
    display: none;
  }
  .navbar-text .change-language button{
   display: block;
  }
  .navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
    justify-content: center !important;
    padding: 0px;
  }
  .social-icon{
    float: right !important;
  }
  .footer p{
    float:left !important;
  }
  .margin-qualification-thing {
    margin-right: 100px !important;
  } 

  .change-language-mb{
    display: block !important;
    float: right;
    border-radius: 5px;
    line-height: 1px;
    font-size: 25px;
    padding: 5px;
  }
  .change-language-mb button {
    margin-right: 6px;
}
  .qualification_textbox{
    margin-right: 0px;
  }
  
  /* .qualification__rounder{
    display: none !important;
  }	
  .line {
    display: flex;
  } */
}

@media screen and (max-device-width: 420px)
and (orientation: portrait) {
  .margin-law-thing{
    margin-right: 100px;
  }
}
@media screen and (max-device-width: 377px)
and (orientation: portrait) {
  .navbar-name.navbar-name {
    padding: 0px 0px 0px 0px !important;
  }
}
p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #015d9f;
}
navbar-brand {
   color: #121212;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  display: inline-block;
  position: relative;
  color: #0087ca;
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
nav.navbar .navbar-nav a.nav-link.navbar-link::after {
  content: '';
  position: absolute;
  width: 85%;
  margin-left: 10px;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #045882;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.navbar-name.navbar-name {
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 24px;
  opacity: 0.75;
}
a.navbar-name.navbar-name:hover,
a.navbar-name.navbar-name.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon,
.change-language {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a,
.change-language a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before,
.change-language a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before,
.change-language a:hover::before {
    transform: scale(1);
}
.social-icon a img,
.change-language a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
 .change-language button {
  border: none;
  padding: 3px;
  font-size: 22px;
  margin-left: 0;
  transition: none !important;
}
.navbar-text .change-language button::before {
  background-color: transparent;
}
.pt{
  border: none;
}
.en{
  border: none;
}
.navbar-text .change-language.active{
  border: 2px solid #53c9fc;
}
.navbar-text .change-language button{
  border-radius: 5px;
  line-height: 1px;
}
.change-language-mb{
  display: none;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 180px 0 100px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  /* background: linear-gradient(90.21deg, rgba(54, 170, 155, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%); */
  background: linear-gradient(at top right, #015d9f 2%, #2A6F97 40%, #2C7DA0 60%, #468FAF 70%, #61A5C2 95%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-style: Courier New, monospace;
  font-size: 70px;
  color: #fff;
  font-weight: normal;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: grid;
}
.banner p {
  color: #fff;
  font-style: Courier New, monospace;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  font-style: Courier New, monospace;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner a{
  text-decoration: none;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #0d4ce1;
}
#scrollDown {text-align: center;}
.scrollingDown a {
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font : normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
}
.scrollingDown a:hover {
  opacity: .5;
}
#scrollDown a {
  padding-top: 70px;
}
#scrollDown a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

/************ Skills Css ************/
.page-wrapper {
  padding: 5%;
  display: flex;
  -webkit-display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  }
.skills h3, p{
    color: #fff;
  }
.skills .page-wrapper .card-wrapper .card-details h3{
  display: flex;
  justify-content: left;
  }
.arrow-icon{
  display: flex;
  float: right;
  position: relative;
  color: #fff;
  margin-left: 20px;
  align-items: center;
  margin-left: auto;
}

.card-wrapper.active .arrow-icon{
  transform: translateY(-10%) rotate(180deg);
  transition: 0.5s;
}

.card-wrapper:not(.active) .arrow-icon {
  transform: translateY(0) rotate(0);
  transition: 0.5s;
} 

.margin{
  margin-left: 5%;
  display: flex;
  align-items: center;
}
.card-wrapper {
  border-left: 4px solid #fff;
  background:#0e5588;
  border-radius: 6px;
  max-height: 100px;
  overflow: hidden;
  padding: 2rem;
  width: 30%;
  transition: max-height 500ms ease-in-out;
  min-width: 300px;
  }
  

.card-wrapper.active {
    box-shadow: 0 0 8px #d3d3d3;
    cursor: pointer;
    max-height: 300px;
    
  }
  
.card-wrapper:not(.active) {
  transform: translateY(0) rotate(0);
  transition: 0.5s;
  }
  
.card-wrapper.active .reveal-details {
      display: block;
      animation-name: reveal-details-animation;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
  }
  
.reveal-details{
  display: none;
  }
.toggle-btn {
    position: relative;
    display: block;
    background: #3890b3;
    color: white;
    width: 300px;
    padding: 10px;
    text-decoration: none;
    margin-bottom: 5px;
}
  
.arrow {
  position: absolute;
  top: 20px;
  right: 20px;
}
  
.arrow::before,
.arrow::after {
  position: relative;
  content: '';
  display: block;
  width: 10px;
  height: 1px;
  background: white;
  transition: 0.3s ease-in-out;
}
  
.arrow::before {
  transform: rotate(45deg);
}

.arrow::after {
  left: 6px;
  top: -1px;
  transform: rotate(-45deg);
}
  
.toggle-btn {
  background: #13749a;
}

.toggle-btn.active .arrow::before {
  transform: rotate(-45deg);
}

.toggle-btn.active .arrow::after {
  transform: rotate(45deg);
}

@keyframes reveal-details-animation {
  0% {
  opacity: 0;
  transform: translateY(-20px);
 }
  100% {
  opacity: 1;
  transform: translateY(0);
 }
  }

.card-wrapper .reveal-details {
  transition: display 0.5s ease-in-out;
  }
    
.card-details, .reveal-details {
  font-weight: 300;
  }

.card-height-animate {
  height: 300px;
  }

/************ Contact Css ************/
.contact {
  padding: 60px 0 80px 0;
}
.contact img {
  bottom: 10px;
  width: 92%;
}
.contact h2 {
  color: #fff;
  font-style: Courier New, monospace;
  font-size: 45px;
  font-weight: 10;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #2A6F97;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 25px 0 50px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer .container .row .col .logo {
  width: 100%;
  text-align: center;
  font-size: medium;
}
.logo_name {
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 20px;
  opacity: 0.75;
  text-decoration: none;
}
a.logo_name:hover,
a.logo_name.active {
    opacity: 1;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
.qualification__section h2{
  color: #fff;
}
.qualification {
  padding: 60px 0 80px 0;
}
.qualification__tabs{
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}
.qualification__button{
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  color: #ffffffcd;
}
.qualification_textbox{
  margin-left: 3rem;
}
.qualification__icon{
  font-size: 1.8rem;
  margin-right: 0.25rem;
  color: #ffffff;
}
.qualification__data{
  position: relative;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
  color: #ffffffb3;
  font-size: 15px;
}
.qualification__title{
  font-size: 0.938rem;
  font-weight: 500;
  color: #fff;
}
.qualification__subtitle{
  display: inline-block;
  font-size: var(--small-font-size);
  margin-bottom: 1rem;
}
.qualification__subtitle .a {
  text-decoration: none !important;
}
/* .qualification__rounder{
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: #0e5588;
  border-radius: 50%;
} */
/* .qualification__line{
  display: block;
  width: 1px;
  height: 100%;
  background-color: #0e5588;
  transform: translate(6px, -7px);
} */
.qualification__line {
  position: absolute;
  top: 50%;
  left: 52%;
  height: 115%;
  border-left: 1px solid #0e5588;
}
.qualification__rounder {
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #0e5588;
}
.qualification__active {
  display: block;
  text-decoration: underline;
  color: #19527a;
}

.qualification__active[data-content]{
  display: block;
  text-decoration: underline;
}
.qualification__hide {
  display: none;
}
.qualification__sections {
  display: grid;
  grid-template-columns: 0.6fr;
  justify-content: center;
}
.container{
  max-width: 768px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.section{
  padding: 50px 0 100px;
}
.section__title{
  color: #fff;
  font-size: 1.7rem;
}
.section__subtitle{
  color: #fff;
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 3rem;
}
.section__title,
.section__subtitle{
  text-align: center;
}
.scrollup{
  position: fixed;
  right: 5rem;
  bottom: -20%;
  color:hsl(0, 0%, 0%);
  opacity: .8;
  padding: 0 .3rem;
  border-radius: .4rem;
  z-index: 10;
  font-size: 30px;
  transition: .4s;
}
.scrollup:hover{
  color: #fff;
}
.show-scroll{
  bottom: 5rem;
}